import React from "react";
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components";

import Layout from "../layouts/layout.js";
import Seo from "../components/seo.js";
import HomeCarousel from "../components/HomeCarousel.js";
import QuoteForm from "../components/QuoteForm.js";
import { Wrapper, SectionH2, SectionParag, Section3Col, Columns, Icon, SectionH3, Section1ColGray, SectionHeaderOnlyWhite, SectionHeaderOnlyGray, SectionButtonOnly, Section1ColWhite } from "../styles/style.js";
import clock from './img-clock.svg'
import car from './img-car.svg'
import crew from './img-crew.svg'
import SectionBottomCta from "../components/SectionBottomCta.js";

const HeroBanner = styled.div`
  position: absolute;
  top: 15%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 180px 50px 100px 50px;

  @media (max-width: 1024px) {
    position: relative;
    top: 0;
    display: block;
    padding: 20px 30px;
  }
`;

const HeroBannerTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 16px;
  padding-bottom: 0;
  border-bottom: none;
  font-size: 72px;
  font-weight: 900;
  color: rgba(255,255,255);
  text-shadow: 2px 2px gray;

  @media (max-width: 1024px) {
    font-size: 40px;
    color: #424242;
    text-align: center;
    text-shadow: none;
  }
`;

const HeroBannerSub = styled.h1`
  font-size: 30px;
  font-weight: 900;
  color: #fff;
  text-shadow: 2px 2px gray;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    font-size: 20px;
    color: #424242;
    text-align: center;
    text-shadow: none;
  }
`;

const BigButton = styled(Link)`
  display: block;
  background-color: orange;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 400;
  text-align: center;
  letter-spacing: 1px;
  padding: 15px 20px;
  border-radius: 3px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  max-width: 250px;
`;

const SmallButton = styled(BigButton)`
  padding: 10px 15px;
  max-width: 200px;
`;

const CityName = "Banning"

const LimoServicePageBanning = () => (
  <Layout>
    <Seo
      title={`${CityName} Limo Service`}
    />

    <HomeCarousel />

    <HeroBanner className="w-full 3xl:max-w-screen-2xl mx-auto">
      <div style={{padding:`25px`}}>
        <HeroBannerTitle>{CityName} Black Limo Service</HeroBannerTitle>
        <HeroBannerSub>
          Amazing Limousine and Sedan is the Best Limo Service in {CityName}
        </HeroBannerSub>
      </div>

      <QuoteForm />
    </HeroBanner>

    <Section3Col>
      <Columns>
        <div className="flex justify-center">
          <Icon src={clock} />
        </div>
        <SectionH3>Book Anytime</SectionH3>
        <SectionParag>
          We want to help you with your reservation as soon as possible. We do have staff answering the phones 7 days a week, but if we are on the other line or it is after hours, please leave a message, email or service quote inquiry, and one of our representatives will get back to you as soon as possible.
        </SectionParag>
      </Columns>
      <Columns>
        <div className="flex justify-center">
          <Icon src={car} />
        </div>
        <SectionH3>Customize Limo</SectionH3>
        <SectionParag>
          All of our vehicles come stocked with soda and waters. Upon request we can customize the vehicle with different types of alcohol, balloons, celebration banners and other items you may need for your trip.
        </SectionParag>
      </Columns>
      <Columns>
        <div className="flex justify-center">
          <Icon src={crew} />
        </div>
        <SectionH3>World-Class Crew</SectionH3>
        <SectionParag>
          With more than 23 years of experience, Amazing Limousines knows how to take care of our clients and make them feel like You Are The Star! Our staff members are professional, courteous and always on time. Our chauffeurs and representatives are here to make sure you have an amazing experience!
        </SectionParag>
      </Columns>
    </Section3Col>

    <Section1ColWhite style={{ paddingTop: 0 }}>
      <SectionH2 style={{ color: `#e7711b` }}>★ ★ ★ ★ ★</SectionH2>
      <SectionParag>Ave. Customer Rating: 4.8</SectionParag>
    </Section1ColWhite>

    <Section1ColGray>
      <Wrapper>
        <SectionH2>Our Packages</SectionH2>
        <SectionParag>
          Amazing Limousines is proud to offer special packages designed to enhance your limousine experience in {CityName}. Packages vary depending on requests. Select from our services below, request a quote, and a representative will contact you at their earliest convenience to share pricing and book your reservation. Thank you for trusting Amazing Limousines!
        </SectionParag>
        <BigButton to="/services/">View All Packages</BigButton>
      </Wrapper>
    </Section1ColGray>

    <Section3Col>
      <Columns>
        <StaticImage
          src="../img/section-services-anniversary.jpg"
          alt="anniversary limo"
          style={{ marginBottom: `10px` }}
        />
        <SectionH3>Anniversaries</SectionH3>
      </Columns>
      <Columns>
        <StaticImage
          src="../img/section-services-bachelor.jpg"
          alt="bachelor bachelorette limo"
          style={{ marginBottom: `10px` }}
        />
        <SectionH3>Bachelor/Bachelorette Parties</SectionH3>
      </Columns>
      <Columns>
        <StaticImage
          src="../img/section-services-birthdays.jpg"
          alt="birthday limo"
          style={{ marginBottom: `10px` }}
        />
        <SectionH3>Birthdays</SectionH3>
      </Columns>
    </Section3Col>

    <Section3Col>
      <Columns>
        <StaticImage
          src="../img/section-services-concerts.jpeg"
          alt="concert limo"
          style={{ marginBottom: `10px` }}
        />
        <SectionH3>Concerts</SectionH3>
      </Columns>
      <Columns>
        <StaticImage
          src="../img/section-services-corporate.jpg"
          alt="corporate limo"
          style={{ marginBottom: `10px` }}
        />
        <SectionH3>Corporate</SectionH3>
      </Columns>
      <Columns>
        <StaticImage
          src="../img/section-services-sightseeing.jpg"
          alt="sightseeing tour limo"
          style={{ marginBottom: `10px` }}
        />
        <SectionH3>Sightseeing</SectionH3>
      </Columns>
    </Section3Col>

    <Section3Col>
      <Columns>
        <StaticImage
          src="../img/section-services-sports.jpg"
          alt="sporting events limo"
          style={{ marginBottom: `10px` }}
        />
        <SectionH3>Sporting Events</SectionH3>
      </Columns>
      <Columns>
        <StaticImage
          src="../img/section-services-weddings.jpg"
          alt="wedding limo"
          style={{ marginBottom: `10px` }}
        />
        <SectionH3>Weddings</SectionH3>
      </Columns>
      <Columns>
        <StaticImage
          src="../img/section-services-wine-tour.jpg"
          alt="wine tour limo"
          style={{ marginBottom: `10px` }}
        />
        <SectionH3>Wine Tours</SectionH3>
      </Columns>
    </Section3Col>

    <div className="relative" style={{ display: "grid", background: "#000000" }}>
      <StaticImage
        style={{
          gridArea: "1/1",
          objectFit: "cover",
          opacity: "0.7",
          height: 800,
        }}
        alt="party limo"
        src="../img/section-about-bg.jpg"
        formats={["auto", "webp", "avif"]}
        width={1920}
      />
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
        <div className="w-11/12 max-w-screen-md mx-auto">
          <div className="text-white text-3xl md:text-5xl font-extrabold text-center">About Us</div>
          <div className="text-white text-lg md:text-2xl font-semibold leading-normal text-center mt-6">
            Welcome to Amazing Limousines. We are your “All Event Limousine Company”, serving {CityName} for the past 23 years. Whether it is just for you and a date or your 30 closest friends, we can help you with any transportation needs you may have: Airport Drop Off and Pick Ups, Anniversaries, Award Ceremonies, Bachelor/Bachelorette Parties, Birthdays, Casino Nights, Charters, Concerts, Conventions, Corporate Functions, Dinner with Friends, Engagements, Funerals, Graduations, Homecomings, Night Out, Proms, Proposals, Quinceaneras, Sightseeing, Special Events, Sporting Events, Theaters, Vegas Trips, Weddings, Wine Tours and so many more.
          </div>
          <BigButton to="/about/">Learn More</BigButton>
        </div>
      </div>
    </div>

    <SectionHeaderOnlyWhite>
      <Wrapper>
        <SectionH2>Choose Your Ride</SectionH2>
      </Wrapper>
    </SectionHeaderOnlyWhite>

    <Section3Col>
      <Columns>
        <StaticImage src="../img/fleet-lincoln-sedan.png" alt="lincoln sedan" />
        <SectionH3>Lincoln Sedan</SectionH3>
        <SectionParag>1 to 3 Passengers</SectionParag>
        <SmallButton to="/fleet-sedan">More Details</SmallButton>
      </Columns>

      <Columns>
        <StaticImage src="../img/fleet-suburban.png" alt="black suburban" />
        <SectionH3>Suburban</SectionH3>
        <SectionParag>3 to 6 Passengers</SectionParag>
        <SmallButton to="/fleet-suburban">More Details</SmallButton>
      </Columns>

      <Columns>
        <StaticImage src="../img/fleet-lincoln-limo.png" alt="lincoln limo" />
        <SectionH3>Lincoln Limo</SectionH3>
        <SectionParag>4 to 9 Passengers</SectionParag>
        <SmallButton to="/fleet-limo">More Details</SmallButton>
      </Columns>
    </Section3Col>

    <Section3Col>
      <Columns>
        <StaticImage src="../img/fleet-van.png" alt="passenger van" />
        <SectionH3>Van</SectionH3>
        <SectionParag>12 Passengers</SectionParag>
        <SmallButton to="/fleet-van">More Details</SmallButton>
      </Columns>

      <Columns>
        <StaticImage src="../img/fleet-sprinter.png" alt="sprinter van" />
        <SectionH3>Sprinter Limo or Van</SectionH3>
        <SectionParag>12 Passengers</SectionParag>
        <SmallButton to="/fleet-sprinter">More Details</SmallButton>
      </Columns>

      <Columns>
        <StaticImage src="../img/fleet-white-hummer-limo.png" alt="hummer limo" />
        <SectionH3>Hummer Limo</SectionH3>
        <SectionParag>18 Passengers</SectionParag>
        <SmallButton to="/fleet-hummer-limo">More Details</SmallButton>
      </Columns>
    </Section3Col>

    <Section3Col>
      <Columns>
        <StaticImage src="../img/fleet-champagne-escalade.png" alt="escalade limo" />
        <SectionH3>Escalade Limo</SectionH3>
        <SectionParag>18 Passengers</SectionParag>
        <SmallButton to="/fleet-escalade">More Details</SmallButton>
      </Columns>

      <Columns>
        <StaticImage src="../img/fleet-party-bus.png" alt="party bus" />
        <SectionH3>Party Bus</SectionH3>
        <SectionParag>10 to 30 Passengers</SectionParag>
        <SmallButton to="/fleet-party-bus">More Details</SmallButton>
      </Columns>
    </Section3Col>

    <SectionHeaderOnlyGray>
      <SectionH2>Amazing Words From Customers Like You</SectionH2>
    </SectionHeaderOnlyGray>

    <div className="relative" style={{ display: "grid", background: "#000000" }}>
      <StaticImage
        style={{
          gridArea: "1/1",
          objectFit: "cover",
          opacity: "0.7",
        }}
        className="2xl:h-screen"
        alt="party limo"
        src="../img/section-testi-bg.jpg"
        formats={["auto", "webp", "avif"]}
        width={1920}
      />
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
        <div className="w-11/12 grid grid-cols-1 xl:grid-cols-3 gap-8 py-12 mx-auto">
          <div className="bg-white opacity-90 p-12 rounded-md">
            <p className="text-lg text-black">
              <em>"I've finally found a limo service that lived up to the reviews. If you need a limo, look no further!! This is by far the best Limousine Service I've ever used. I called Art for a last minute Limo and he couldn't have been nicer or more accommodating. I used the service for a concert in Hollywood and from the time they picked us up until they dropped us off 8 hours later, the service was impeccable. The limo was clean, Julio the driver was the best driver I've ever had, he was professional, funny, courteous and even more important, punctual. I won't even think about calling another service in the future. Thanks Art (and Julio) for making our night a great one!!"</em><br/><br/><span style={{ fontSize: `14px`, color: `#32325d` }}>--David L. from Fullerton, CA</span>
            </p>
          </div>
          <div className="bg-white opacity-90 p-12 rounded-md">
            <p className="text-lg text-black">
              <em>"Art and Amazing Limousines are hands down the best of the best! We hired a limo for our wedding in Oak Glen, Ca. to take us to Los Angeles for our wedding night. We needed to make a stop to pick up our luggage before being taken to L.A., as we were leaving for Maui early the next morning, and they were so accommodating! We had taken our card box with us in the limo only to realize that we couldn't get it back home or fit it in a suit case, Art took the box and kept it in his office where my mother-in-law was able to pick it up that week. Talk about helpful! We are so grateful Art for making the end of our wedding and trip to start our honeymoon stress free. We would highly recommend this company to everyone!"</em><br/><br/><span style={{ fontSize: `14px`, color: `#32325d` }}>--Kayla P. from Redlands, CA</span>
            </p>
          </div>
          <div className="bg-white opacity-90 p-12 rounded-md">
            <p className="text-lg text-black">
              <em>"Great service at Amazing Limousines from booking all the way to the day of the actual event. Our initial limo service cancelled on us sighting that their cars won't make it uphill to our church thus leaving us in a dilemma. Art stepped up to the challenge when we explained the situation and took the extra steps to check the church location and actually making a test drive to ensure we have an amazing smooth drive to our wedding ceremony. Gabriel the driver was prompt on time, so helpful and extremely professional. Thanks to Art and Gabriel we had a great amazing ride all around. Thank you!!!"</em><br/><br/><span style={{ fontSize: `14px`, color: `#32325d` }}>--Martina S. from West Covina, CA</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <SectionButtonOnly>
      <a href="https://www.yelp.com/biz/amazing-limousines-riverside" target="_blank" rel="noopener noreferrer"><StaticImage src="../img/5-star-yelp.png" alt="Amazing Limousines Yelp review" /></a>
    </SectionButtonOnly>

    <SectionBottomCta city={CityName} />
  </Layout>
);

export default LimoServicePageBanning